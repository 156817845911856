import { AuthService } from 'libs/acl/src/lib/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  catchError,
  switchMap,
  throwError,
  from,
  timer,
  mergeMap,
  filter,
  first,
} from 'rxjs';
import { environment } from '@firebird-web/shared-config';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the request is for OAuth token refresh or acquisition
    if (this.isOAuthRequest(req)) {
      // Directly forward OAuth token requests without waiting for AuthService initialization
      return next.handle(req);
    }

    return this.authService.hasInitialized().pipe(
      filter((initialized) => initialized), // Wait until the AuthService is initialized
      first(), // Only take the first true value
      switchMap(() => {
        // Now that AuthService is initialized, handle the request as before
        if (this.authService.hasValidAccessToken()) {
          return from(this.handleRequestWithToken(req, next));
        }

        if (!this.authService.getRefreshToken()) {
          this.authService.logout();
          return throwError(
            () => new Error('Refresh token not available. User logged out.')
          );
        }

        return from(this.authService.refreshToken()).pipe(
          switchMap(() => this.handleRequestWithToken(req, next)),
          catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.authService.logout();
              return throwError(
                () => new Error('Unable to refresh token. User logged out.')
              );
            }
            return this.handleNetworkError(req, next);
          })
        );
      })
    );
  }

  private isOAuthRequest(req: HttpRequest<any>): boolean {
    // Implement logic to identify OAuth token requests
    // This could be based on the URL, headers, or other request properties
    return req.url.includes(environment.identityServer);
  }

  private handleRequestWithToken(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getAccessToken(); // get the token form the authservice
    const headers = req.headers.set('Authorization', `Bearer ${token}`); //add the bearer token to authorization header
    const authReq = req.clone({ headers }); // clone the request with the added auth header
    return next.handle(authReq); // sned the request off
  }

  private handleNetworkError(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const retries = 3;
    const delayMs = 1000; // Delay in milliseconds between retries
    return next
      .handle(req)
      .pipe(
        catchError((error, caught) =>
          this.retryRequest(error, caught, retries, delayMs)
        )
      );
  }

  private retryRequest(
    error: any,
    caught: Observable<HttpEvent<any>>,
    retries: number,
    delayMs: number
  ): Observable<HttpEvent<any>> {
    if (retries > 0) {
      return timer(delayMs).pipe(
        mergeMap(() => caught),
        catchError((err) =>
          this.retryRequest(err, caught, retries - 1, delayMs)
        )
      );
    } else {
      return throwError(
        () => new Error('Network error after retries: ' + error.message)
      );
    }
  }
}
