import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'firebird-web-checkbox',
  templateUrl: './checkbox.component.html',
})
export class CheckboxComponent {
  @Input()
  checked!: boolean;

  @Input()
  disabled!: boolean;

  @Input()
  id!: string;

  @Input()
  labelPosition: 'before' | 'after' = 'after';

  @Input()
  name!: string;

  @Input()
  labelKey!: string;

  @Input()
  translateParams = {};

  @Input()
  inputClass!: string;

  @Input()
  isDynamicLabel = false;

  @Output()
  public changeEvent: EventEmitter<Event> = new EventEmitter<Event>();

  public click($event: any): void {
    if (!this.disabled) {
      this.changeEvent.emit($event);
    }
  }
}
