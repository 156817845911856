import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from './date-ago/date-ago.pipe';
import { UtcDatePipe } from './utc-date/utc-date.pipe';
import { UnitsPipe } from './units-pipe/units.pipe';
import { DateWithTimezonePipe } from './date-timezone/date-timezone.pipe';
import {
  BypassSecurityTrustResourceUrlPipe,
  SafeHtmlPipe,
} from './SanitizePipe/sanitize-url.pipe';
import { DecimalFormatPipe } from './decimal-format/decimal-format.pipe';
import { LocaTimezonePipe } from './local-timezone/loca-timezone.pipe';
import { DateWithoutTimezonePipe } from './format-date-without-timezone/format-date-without-timezone.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    DateAgoPipe,
    UtcDatePipe,
    UnitsPipe,
    DateWithTimezonePipe,
    BypassSecurityTrustResourceUrlPipe,
    SafeHtmlPipe,
    DecimalFormatPipe,
    LocaTimezonePipe,
    DateWithoutTimezonePipe,
  ],
  providers: [DateWithTimezonePipe],
  exports: [
    DateAgoPipe,
    UtcDatePipe,
    UnitsPipe,
    DateWithTimezonePipe,
    BypassSecurityTrustResourceUrlPipe,
    SafeHtmlPipe,
    DecimalFormatPipe,
    LocaTimezonePipe,
    DateWithoutTimezonePipe,
  ],
})
export class SharedPipesModule {}
