import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRouteState } from '../interfaces/route-state.interface';

export const getRouterState =
  createFeatureSelector<RouterReducerState<IRouteState>>('router');

/**
 * getCurrentRoute
 */
export const getCurrentRoute = createSelector(getRouterState, (router) => {
  return router.state;
});

/**
 * getQueryParams
 */
export const getQueryParams = createSelector(getRouterState, (router) => {
  return router.state.queryParams;
});

/**
 * getQueryParam
 */
export const getQueryParam = (paramName: string) =>
  createSelector(getRouterState, (router: RouterReducerState<IRouteState>) => {
    return router.state.queryParams[paramName];
  });

/**
 * getRouteParams
 */
export const getRouteParams = createSelector(getRouterState, (router) => {
  return router.state.params;
});

/**
 * getRouteParam
 */
export const getRouteParam = (paramName: string) =>
  createSelector(getRouterState, (router) => {
    return router.state.params[paramName];
  });
