/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'firebird-web-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  constructor(private router: Router) {}

  @Input()
  activeClass = false;

  @Input()
  routeUrl!: string;

  @Input()
  urlQueryParams!: { [key: string]: string };

  @Input()
  iconName!: string;

  @Input()
  disabled = false;

  @Input()
  iconWidth = '25px';

  @Input()
  iconHeight = '25px';

  @Input()
  hideContent: boolean | null = false;

  @Input()
  type: 'bg-on-hover' | 'button' | 'default' = 'default';

  @Output()
  public clickEvent: EventEmitter<Event> = new EventEmitter<Event>();

  public isHover = false;

  public click($event: Event): void {
    $event.preventDefault();
    if (!this.disabled) {
      if (this.routeUrl) {
        this.router.navigate([this.routeUrl]);
      } else {
        this.clickEvent.emit($event);
      }
    }
  }

  public onMouseOver(_$event: Event): void {
    this.isHover = true;
  }

  public onMouseOut(_$event: Event): void {
    this.isHover = false;
  }
}
