<div class="permission-dialog component">
  <div class="header">
    <div class="header-section">
      <firebird-web-icon
        class="lock-icon"
        name="lock"
        height="24px"
        width="24px"
      ></firebird-web-icon>
      <span translate="subscription"></span>
    </div>
    <button class="close" mat-dialog-close>
      <span class="icon"></span>
    </button>
  </div>
  <div class="permission-body">
    <p>This feature requires an additional subscription.</p>
    <p>
      You can unlock access to features by upgrading your service plan or
      request a free trial.
    </p>
    <br />
    <p>Reach out to learn more or enable a trial today.</p>
  </div>
  <div class="permission-footer">
    <a href="mailto:sales@atmosphericg2.com" mat-button class="contact-button">
      {{ 'contactus_button_label' | translate }}
    </a>
  </div>
</div>
