<button
  mat-button
  [attr.id]="id"
  [attr.name]="name"
  [attr.value]="value"
  [disabled]="disabled"
  [className]="style + '-btn'"
  [ngClass]="{ ignoreHover: ignoreHover }"
  (click)="click($event)"
>
  <span class="icon icon-first" *ngIf="iconName && iconFirst">
    <firebird-web-icon
      [name]="iconName"
      width="14px"
      height="14px"
    ></firebird-web-icon
  ></span>
  <span class="label" [translate]="labelKey"></span>
  <span class="icon" *ngIf="iconName && !iconFirst"
    ><firebird-web-icon
      [name]="iconName"
      [width]="iconWidth || '12px'"
      [height]="iconHeight || '12px'"
    ></firebird-web-icon
  ></span>
  <ng-content></ng-content>
</button>
