/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDialogInput } from './../../interfaces';
@Component({
  selector: 'firebird-web-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDialogInput,
    private dialogRef: MatDialogRef<DialogComponent>
  ) {}

  /**
   * Returns true if passed reference is of type TemplateRef
   */
  public isTemplate(reference: any): boolean {
    return reference instanceof TemplateRef;
  }
  public onManageClick(): void {
    this.dialogRef.close('Manage');
  }
}
