<div class="dialog component">
  <div
    class="dialog-header"
    [ngStyle]="
      data.headerPaddingX
        ? { padding: '20px ' + data.headerPaddingX + 'px' }
        : null
    "
    *ngIf="data.title"
  >
    <div class="title-wrapper">
      <span *ngIf="data.showPreviousButton" mat-dialog-close>
        <mat-icon class="arrow-back">arrow_back_ios</mat-icon>
      </span>
      <h3 class="dialog-title" translate>
        {{ data.title }}
      </h3>
    </div>
    <div class="close-wrapper">
      <firebird-web-button
        [type]="'button'"
        [style]="'additional'"
        [name]="'manage'"
        [labelKey]="'Manage' | translate"
        (clickEvent)="onManageClick()"
        ngClass="manage"
        *ngIf="data.showManage"
      ></firebird-web-button>
      <span class="dialog-close" mat-dialog-close>
        <span class="icon"></span>
      </span>
    </div>
  </div>

  <div class="dialog-content">
    <ng-container
      *ngIf="data?.component && isTemplate(data?.component)"
      [ngTemplateOutlet]="data.component"
      [ngTemplateOutletContext]="data"
    ></ng-container>

    <ng-container
      *ngIf="data?.component && !isTemplate(data?.component)"
      [ngComponentOutlet]="data.component"
    ></ng-container>
  </div>
</div>
