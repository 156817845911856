import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateWithTimezone',
  pure: true,
})
export class DateWithTimezonePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, format?: string) {
    if (!value) {
      return value;
    }

    const date = new Date(value);
    const timeZone = this.getTimeZoneAbbr(date);

    if (format === undefined) {
      format = 'MMM d, y h:mm aaa';
    }

    const formattedDate = this.datePipe.transform(date, format);
    return `${formattedDate} ${timeZone}`;
  }

  private getTimeZoneAbbr(date: Date): string {
    if (typeof Intl === 'undefined' || !('DateTimeFormat' in Intl)) {
      return '';
    }

    const formatter = new Intl.DateTimeFormat(undefined, {
      timeZoneName: 'short',
    });
    const timeZones = formatter.formatToParts(date);

    for (let index = 0; index < timeZones.length; index++) {
      if (timeZones[index].type === 'timeZoneName') {
        return timeZones[index].value;
      }
    }

    return '';
  }
}
