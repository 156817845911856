<label class="checkbox component">
  <ng-container *ngIf="labelPosition === 'before'"
    ><ng-container *ngTemplateOutlet="label"></ng-container
  ></ng-container>
  <input
    type="checkbox"
    [attr.id]="id"
    [attr.name]="name"
    [disabled]="disabled"
    [checked]="checked"
    (change)="click($event)"
    [ngClass]="{
      'left-gap': labelPosition === 'before',
      'right-gap': labelPosition === 'after',
      'gray-style': inputClass === 'gray-style',
      'widget-decimal': inputClass === 'widget'
    }"
  />
  <ng-container *ngIf="labelPosition === 'after'"
    ><ng-container *ngTemplateOutlet="label"></ng-container
  ></ng-container>
</label>
<ng-template #label>
  <span
    *ngIf="!isDynamicLabel"
    [ngClass]="{ 'widget-decimal': inputClass === 'widget' }"
    translate
    [translateParams]="translateParams"
  >
    {{ labelKey }}
  </span>

  <span
    *ngIf="isDynamicLabel"
    [ngClass]="{ 'widget-decimal': inputClass === 'widget' }"
  >
    {{ labelKey | translate }}
  </span>
</ng-template>
