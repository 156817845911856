<mat-button-toggle-group [value]="selectedLabelKey" class="toggle-tabs">
  <ng-container *ngFor="let data of labelData; trackBy: identify">
    <mat-button-toggle
      [value]="data.labelKey"
      (click)="selectTab(data)"
      [hasViewPermission]="data.permission"
      [iconExtraStyles]="lockIconStyles"
      [alignRight]="true"
      class="toggle"
    >
      <firebird-web-icon
        class="icons"
        *ngIf="data.iconName"
        [name]="data.iconName"
        width="16px"
        height="16px"
      >
      </firebird-web-icon>

      <span [translate]="data.labelKey"></span>
    </mat-button-toggle>
  </ng-container>
</mat-button-toggle-group>
