<div class="autocomplete-container">
  <mat-form-field floatLabel="never" class="autocomplete-searchbox">
    <input
      type="text"
      [placeholder]="placeholder | translate"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
    />
    <span class="search-icon">
      <firebird-web-icon [name]="'search'" width="12px"></firebird-web-icon>
    </span>
    <mat-autocomplete class="autocomplete-box" #auto="matAutocomplete">
      <mat-option *ngIf="isLoading" translate="loading"></mat-option>
      <mat-option
        *ngIf="
          !isLoading &&
            (myControl!.value?.length || 0) >= 3 &&
            filteredResults?.length === 0;
          else resultsView
        "
        >{{ 'noResults' | translate }}</mat-option
      >
      <ng-template #resultsView>
        <mat-option
          (onSelectionChange)="selectionChange(result)"
          *ngFor="let result of filteredResults; trackBy: trackByFnGroup"
          [value]="result.displayName"
        >
          {{ getSiteDisplayText(result) }}
        </mat-option>
      </ng-template>
    </mat-autocomplete>
  </mat-form-field>
</div>
