<div class="popover component">
  <div class="popover-body">
    <ng-container
      *ngIf="popoverInput.templateRef"
      [ngTemplateOutlet]="popoverInput.templateRef"
      [ngTemplateOutletContext]="popoverInput.data"
    >
    </ng-container>

    <ng-container
      *ngIf="popoverInput.component"
      [ngComponentOutlet]="popoverInput.component"
    >
    </ng-container>
  </div>
</div>
