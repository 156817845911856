<div class="loader component d-flex-center">
  <div
    class="lds-spinner"
    [ngStyle]="{
        width, height
    }"
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
