import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from '@firebird-web/shared-config';

let enableSentry = true;
if (environment.production) {
  enableProdMode();
}

if (
  environment.sentryEnv === 'local' ||
  environment.sentryEnv.startsWith('pre-dev')
) {
  enableSentry = false;
}

Sentry.init({
  dsn: 'https://919559628056e29baece83de57847090@o4507181837189120.ingest.us.sentry.io/4507505452187648',
  environment: environment.sentryEnv,
  enabled: enableSentry,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.01, // 1% of transactions will be sent to Sentry
  tracePropagationTargets: ['localhost', environment.apiDomain],
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  });
