export const combinedPermissionsOrMapping = [
  {
    name: 'View__Blurb_Blog',
    permissions: ['View__Forecaster_Blogs', 'View__Forecaster_Blurbs'],
  },
  {
    name: 'View__FreshLook_Table',
    permissions: [
      'View__FreshLook_Table__NA__Gold_East',
      'View__FreshLook_Table__NA__Gold_West',
    ],
  },
  {
    name: 'View__Detailed_Discussion',
    permissions: [
      'View__Forecaster_Discussions__1_to_5_Day__NA',
      'View__Forecaster_Discussions__1_to_5_Day__EUR',
      'View__Forecaster_Discussions__Medium_Range__NA',
      'View__Forecaster_Discussions__Medium_Range__EUR',
    ],
  },
  {
    name: 'View__Midday_Update',
    permissions: [
      'View__Midday_Blogs',
      'View__FirstLook',
      'View__Midday_Model_Analysis_Graphics',
    ],
  },
  {
    name: 'View__Wind_Graphics_80m__NA',
    permissions: [
      'View__Wind_Graphics_80m__NA_East',
      'View__Wind_Graphics_80m__NA_West',
    ],
  },
];

export const isoLocationsPermissions = [
  {
    name: 'PoolSet__ISO-Locations',
    url: `/api/v1/Historical/weighted-regions/HISTORICAL_WEIGHTED_TEMPERATURE`,
  },
];
