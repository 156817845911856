<ng-container *ngIf="type">
  <h1 *ngIf="type === 'h1'" [className]="type" [ngStyle]="styleOverrides">
    <span *ngIf="labelKey; else ngContent" translate>{{ labelKey }}</span>
  </h1>
  <h2 *ngIf="type === 'h2'" [className]="type" [ngStyle]="styleOverrides">
    <span *ngIf="labelKey; else ngContent" translate>{{ labelKey }}</span>
  </h2>
  <h3 *ngIf="type === 'h3'" [className]="type" [ngStyle]="styleOverrides">
    <span *ngIf="labelKey; else ngContent" translate>{{ labelKey }}</span>
  </h3>
  <h4 *ngIf="type === 'h4'" [className]="type" [ngStyle]="styleOverrides">
    <span *ngIf="labelKey; else ngContent" translate>{{ labelKey }}</span>
  </h4>
  <h5 *ngIf="type === 'h5'" [className]="type" [ngStyle]="styleOverrides">
    <span *ngIf="labelKey; else ngContent" translate>{{ labelKey }}</span>
  </h5>
  <h6 *ngIf="type === 'h6'" [className]="type" [ngStyle]="styleOverrides">
    <span *ngIf="labelKey; else ngContent" translate>{{ labelKey }}</span>
  </h6>
</ng-container>

<ng-template #ngContent>
  <ng-content></ng-content>
</ng-template>
