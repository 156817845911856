import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'firebird-web-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  public color: ThemePalette;

  @Input()
  disabled!: boolean;

  @Input()
  iconFirst = false;

  @Input()
  ignoreHover = false;

  @Input()
  iconName!: string;

  @Input()
  iconWidth?: string;

  @Input()
  iconHeight?: string;

  @Input()
  public type: 'button' | 'submit' | 'reset' = 'button';

  @Input()
  public style:
    | 'additional'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'basic' = 'basic';

  @Input()
  public id!: string;

  @Input()
  public name!: string;

  @Input()
  public value!: string;

  @Input()
  public labelKey!: string;

  @Output()
  public clickEvent: EventEmitter<Event> = new EventEmitter<Event>();

  public click($event: Event): void {
    if (!this.disabled) {
      this.clickEvent.emit($event);
    }
  }
}
