import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverDirective } from './directives/popover/popover.directive';
import { AffordanceComponent } from './components/affordance/affordance.component';
import { AutocompleteSearchboxComponent } from './components/autocomplete-searchbox/autocomplete-searchbox.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { HeadingComponent } from './components/heading/heading.component';
import { IconComponent } from './components/icon/icon.component';
import { InfoboxComponent } from './components/infobox/infobox.component';
import { InputPwdComponent } from './components/input-pwd/input-pwd.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { LinkComponent } from './components/link/link.component';
import { ToggleTabsComponent } from './components/toggle-tabs/toggle-tabs.component';
import { PopoverComponent } from './components/popover/popover.component';
import { VerticalTabsComponent } from './components/vertical-tabs/vertical-tabs.component';
import { PaginationControlComponent } from './components/pagination-control/pagination-control.component';
import { LoaderComponent } from './components/loader/loader.component';
import { GridLoaderComponent } from './components/grid-loader/grid-loader.component';
import { SharedPipesModule } from '@firebird-web/shared/pipes';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastComponent } from './components/toast/toast.component';
import { PermissionDialogComponent } from './components/permission-dialog/permission-dialog.component';
import { PermissionsModule } from '@firebird-web/acl';

const sharedComponents = [
  IconComponent,
  HeadingComponent,
  DropdownComponent,
  ButtonComponent,
  InfoboxComponent,
  ButtonComponent,
  InputTextComponent,
  InputPwdComponent,
  LinkComponent,
  CheckboxComponent,
  AutocompleteSearchboxComponent,
  ToggleTabsComponent,
  AffordanceComponent,
  AutocompleteSearchboxComponent,
  DialogComponent,
  PopoverComponent,
  VerticalTabsComponent,
  PermissionDialogComponent,
  PaginationControlComponent,
  LoaderComponent,
  GridLoaderComponent,
  ToastComponent,
];

const sharedDirectives = [PopoverDirective];
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPipesModule,
    RouterModule.forChild([]),
    MatSnackBarModule,
    PermissionsModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    SharedPipesModule,
    MatSnackBarModule,
    ...sharedComponents,
    ...sharedDirectives,
  ],
  declarations: [...sharedComponents, ...sharedDirectives],
})
export class SharedUiModule {}
