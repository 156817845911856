import { Component } from '@angular/core';
import { ILoadingOverlayParams } from 'ag-grid-community';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'firebird-web-grid-loader',
  templateUrl: './grid-loader.component.html',
})
export class GridLoaderComponent implements ILoadingOverlayAngularComp {
  public params!: ILoadingOverlayParams & { loadingMessage: string };

  public agInit(
    params: ILoadingOverlayParams & { loadingMessage: string }
  ): void {
    this.params = params;
  }
}
