import { EventEmitter, Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { DialogComponent } from '../../components';
import { IDialogInput } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
//TODO: remove  providedIn: 'root'
export class DialogService {
  public dialogData = new EventEmitter();
  public dialogRef!: MatDialogRef<DialogComponent>;
  private defaultCofig: MatDialogConfig = {
    panelClass: 'firebird-dialog-panel',
    backdropClass: 'firebird-dialog-backdrop',
  };

  constructor(private dialog: MatDialog) {}

  public openDialog({
    config = {},
    title,
    component,
    context = {},
    params = {},
    showPreviousButton = false,
    showManage = false,
    headerPaddingX = undefined,
  }: IDialogInput): MatDialogRef<DialogComponent> {
    this.dialogRef = this.dialog.open(DialogComponent, {
      ...this.defaultCofig,
      ...config,
      data: {
        ...config.data,
        title,
        component,
        context,
        params,
        showPreviousButton,
        showManage,
        headerPaddingX,
      },
    });
    return this.dialogRef;
  }
}
