import { Injectable } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { CreateListState } from '../interfaces';
import { City } from '../../../../../../shared/components/src/lib/location-selection/location.interface';
import { moveItemsInArray } from '../helpers';

@Injectable()
export class CreateListService {
  public clearSelectedStateFoOutsideClick(
    event: Event,
    listsState: CreateListState
  ): void {
    const element = event.target as Element | null;
    if (!element?.classList?.contains('city-item')) {
      listsState.selectedCityList.clear();
    }
    if (!element?.classList?.contains('favorite-city-item')) {
      listsState.selectedFavoriteCityList.clear();
    }
  }

  public sortElementOnArrowButton(
    event: KeyboardEvent,
    selectedCities: City[],
    elementsList: City[]
  ): void {
    const [selectedCity] = selectedCities;
    const highlightedCityPosition = elementsList.findIndex(
      ({ displayName }) => selectedCity.displayName === displayName
    );

    if (event.key === 'ArrowUp' && highlightedCityPosition > 0) {
      elementsList[highlightedCityPosition] =
        elementsList[highlightedCityPosition - 1];
      elementsList[highlightedCityPosition - 1] = selectedCity;
    }
    if (
      event.key === 'ArrowDown' &&
      highlightedCityPosition >= 0 &&
      elementsList.length > highlightedCityPosition + 1
    ) {
      elementsList[highlightedCityPosition] =
        elementsList[highlightedCityPosition + 1];
      elementsList[highlightedCityPosition + 1] = selectedCity;
    }
  }

  public sortItems(
    event: CdkDragDrop<City[]>,
    isSingleSort: boolean,
    elements: City[]
  ): void {
    if (isSingleSort) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      moveItemsInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
        [...elements]
      );
    }
  }

  public updateCityListAfterDrop(
    event: CdkDragDrop<City[]>,
    isSingleDrop: boolean,
    selectedCities: Set<City>
  ): void {
    if (isSingleDrop) {
      const itemToAdd = event.previousContainer.data[event.previousIndex];
      event.container.data.splice(event.currentIndex, 0, itemToAdd);
      return;
    } else {
      [...selectedCities].forEach((element: City) => {
        event.container.data.splice(event.currentIndex, 0, element);
      });
      selectedCities.clear();
    }
  }

  public removeElementsAfterDrop(
    cities: City[],
    selectCities: Set<City>
  ): void {
    for (let i = cities.length - 1; i >= 0; i--) {
      const obj = cities[i];

      if (selectCities.has(obj)) {
        cities.splice(i, 1);
      }
    }
  }
}
