import { createAction, props } from '@ngrx/store';

export type BaseMessageType = {
  productId: string;
  view: string;
};

export type AppVersionMessageType = {
  applicationName: string;
  version: string;
  updateTime: string;
};

export type WSModelsMessage = {
  model: string;
  view: string;
  parameter: string;
  dataTable?: string;
};

export type WSClusterGraphicMessage = {
  model: string;
  parameter: string;
  view: string;
};

export type WSSummaryGraphicsMessage = {
  groupName: string;
  region: string;
};

export const modelsNewDataAvailable = createAction(
  '[WS] New data for models available',
  props<{ data: WSModelsMessage[] }>()
);

// move to ws store
export const newDataAvailable = createAction(
  '[WS] New data available',
  props<{ data: WSModelsMessage[] | null }>()
);

export const newStaticGraphicsAvailable = createAction(
  '[WS] New static graphics available',
  props<{ data: BaseMessageType[] }>()
);

export const newSummaryGraphicsAvailable = createAction(
  '[WS] New summary graphics available',
  props<{ data: WSSummaryGraphicsMessage[] }>()
);

export const newNotificationsAvailable = createAction(
  '[WS] New notifications available'
);

export const forceGetNewData = createAction('[WS] Force get new data');
