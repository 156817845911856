import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import {
  animationFrameScheduler,
  combineLatest,
  combineLatestWith,
  filter,
  first,
  map,
  observeOn,
  switchMap,
} from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as UserSettingsActions from './+state/user-settings/user-settings.actions';
import * as UserSettingsSelectors from './+state/user-settings/user-settings.selectors';
import { PermissionsService } from 'libs/acl/src/lib/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingService {
  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly permissionsService: PermissionsService
  ) {}

  getCurrentLocationUrlArray(): string[] {
    return this.location
      .path()
      .split('?')[0]
      .split('/')
      .filter((path) => !!path);
  }

  getParentLocationUrlArray(goUpBy: number): string[] {
    const currentUrlSegments = this.getCurrentLocationUrlArray();
    return currentUrlSegments.slice(
      0,
      currentUrlSegments.length - Math.abs(goUpBy)
    );
  }

  getCurrentLocationQueryParams(): Params {
    return this.activatedRoute.snapshot.queryParams;
  }

  getRouterPersistenceData(goUpBy = 0) {
    const urlSegments = this.getParentLocationUrlArray(goUpBy);

    return this.getRouterPersistence(urlSegments).pipe(
      first(),
      map((data) => (typeof data === 'object' && data) || {})
    );
  }

  getPersistenceDataByRoute(urlSegments: string[]) {
    return this.getRouterPersistence(urlSegments).pipe(
      first(),
      map((data) => (typeof data === 'object' && data) || {})
    );
  }

  saveDataOnRouteNavigationStart(goUpBy = 0) {
    const currentUrlSegments = this.getCurrentLocationUrlArray();
    const urlSegments = this.getParentLocationUrlArray(goUpBy);

    this.saveRoute(currentUrlSegments.join('/'));

    return (data: any, isQueryParams = false, clearPrevState = false) =>
      isQueryParams
        ? this.saveQueryParamsState(urlSegments.join('/'), data)
        : this.saveState(urlSegments.join('/'), data, clearPrevState);
  }

  saveState(path: string, value: any, clearPrevState = false) {
    this.store.dispatch(
      UserSettingsActions.changeRouterPersistence({
        path,
        value,
        clearPrevState,
      })
    );
  }

  saveQueryParamsState(path: string, value: any) {
    this.store.dispatch(
      UserSettingsActions.changeQueryParamsPersistence({ path, value })
    );
  }

  saveRoute(path: string) {
    this.store.dispatch(UserSettingsActions.changeActiveRoute({ path }));
  }

  getRouterPersistence(path: string[]) {
    return this.store.pipe(select(UserSettingsSelectors.loaded)).pipe(
      filter((loaded: boolean) => loaded),
      switchMap(() => {
        return this.store.pipe(
          select(UserSettingsSelectors.getRouterPersistenceData(path))
        );
      })
    );
  }

  navigateToPersistedSubRoute(
    activatedRoute: ActivatedRoute,
    redirectData?: any,
    key?: any
  ) {
    const currentUrlSegments = this.getCurrentLocationUrlArray();
    const urlSegments$ = combineLatest(
      activatedRoute.pathFromRoot.map((snapshot) => snapshot.url)
    );
    const firstChild = activatedRoute.routeConfig?.children
      ?.filter(({ data }) => {
        const requiredPermissions = data?.['permissions'] as string[];
        if (!requiredPermissions || !requiredPermissions.length) {
          return true;
        }
        return key
          ? this.permissionsService.isPermitted(requiredPermissions, key)
          : this.permissionsService.isPermitted(requiredPermissions);
      })
      .map(
        ({ path, data }) => data?.['redirectByDefault']?.(redirectData) || path
      )

      .find((path) => !!path);

    // TODO: remove observeOn if in the new version issue with asynchronous data in routing is fixed.
    urlSegments$
      .pipe(
        combineLatestWith(this.getRouterFullPersistence()),
        observeOn(animationFrameScheduler),
        first()
      )
      .subscribe(([urlSegments, persistance]) => {
        const routerUrlSegments = urlSegments
          .reduce((acc, urlSegments) => acc.concat(urlSegments), [])
          .map(({ path }) => path)
          .filter((urlPath) => !!urlPath);

        const data = routerUrlSegments.reduce<Record<string, any>>(
          (acc, item) => acc[item] || {},
          persistance
        );
        if (routerUrlSegments.length === currentUrlSegments.length) {
          this.router.navigate([data['childRoute'] || firstChild], {
            relativeTo: activatedRoute,
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        }
      });
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      first()
    );
  }

  getRouterFullPersistence() {
    return this.store.pipe(select(UserSettingsSelectors.getRouterPersistance));
  }
}
