import { Component, Input } from '@angular/core';

@Component({
  selector: 'firebird-web-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input()
  public width = '80px';

  @Input()
  public height = '80px';
}
