import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsNotificationsComponent } from './components/alerts-notifications/alerts-notifications.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import {
  SharedPipesModule,
  DateWithTimezonePipe,
} from '@firebird-web/shared/pipes';
import { DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SharedUiModule } from '@firebird-web/shared-ui';

@NgModule({
  imports: [CommonModule, SharedPipesModule, MatIconModule, SharedUiModule],
  declarations: [AlertsNotificationsComponent, AlertDialogComponent],
  providers: [DateWithTimezonePipe, DatePipe],
  exports: [AlertsNotificationsComponent],
})
export class AlertsNotificationsModule {}
