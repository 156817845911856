import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@firebird-web/shared-config';
import { Units } from '@firebird-web/user-settings-store';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSettingApiService {
  constructor(private readonly http: HttpClient) {}

  public getUnitSettings(): Observable<Units> {
    return this.http
      .get<{ settings: Units }>(
        `${environment.apiDomain}/api/v1/TabSettings/user/tab-settings`
      )
      .pipe(map(({ settings }) => settings));
  }

  public saveUnitSettings(settings: Units): Observable<void> {
    return this.http.post<void>(
      `${environment.apiDomain}/api/v1/TabSettings/tab-settings/save`,
      { settings }
    );
  }
}
