import { City } from '../../../../../../shared/components/src/lib/location-selection/location.interface';

export const moveItemsInArray = (
  list: City[],
  fromIndex: number,
  toIndex: number,
  selectedCities: City[]
) => {
  const from = limitToRange(fromIndex, list.length - 1);
  const to = limitToRange(toIndex, list.length - 1);

  if (from === to) {
    return;
  }

  if (selectedCities.length > 0) {
    const selectedItems = [];

    for (const item of selectedCities) {
      const toPush = list.find(
        (element: City) => element.siteId === item.siteId
      );

      if (toPush) {
        selectedItems.push(toPush);
      }
    }
    for (let i = 0; i < selectedCities.length; i++) {
      list.splice(list.indexOf(selectedItems[i]), 1);
    }

    const adjustedLength = to - selectedItems.length + 1;
    const adjustedDestinationIndex =
      from < to && adjustedLength >= 0 ? adjustedLength : to;

    if (adjustedDestinationIndex > list.length) {
      list.push(...selectedItems);
    } else {
      list.splice(adjustedDestinationIndex, 0, ...selectedItems);
    }
  }
};

const limitToRange = (value: number, max: number): number => {
  return Math.max(0, Math.min(max, value));
};
