export const environment = {
  production: true,
  sentryEnv: 'production',
  apiDomain: 'https://app-api.atmosphericg2.com',
  wsDomain: 'https://websocket.atmosphericg2.com',
  identityServer: 'https://auth.atmosphericg2.com',
  clientid: 'ag2-trader-app',
  wsEnabled: true,
  disableLinksForEuropeRelease: true,
};
