import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { first, Subscription } from 'rxjs';
import { IPopoverInput } from '../../interfaces';
import { PopoverComponent } from './../../components';
import { PopupPositionConfig } from './popopver.config';

@Directive({
  selector: '[firebirdWebPopover]',
})
export class PopoverDirective implements OnDestroy {
  @Input('firebirdWebPopover') data!: IPopoverInput;
  private popoverRef!: OverlayRef;
  backdropClickSubscription!: Subscription;
  listener!: any;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  show(hasBackdrop = false) {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(PopupPositionConfig)
      ?.withPush(false);

    this.popoverRef = this.overlay.create({
      hasBackdrop: hasBackdrop,
      backdropClass: '',
      positionStrategy,
      panelClass: ['popover-panel', this.data.data.type],
    });
    const popoverRef: ComponentRef<PopoverComponent> = this.popoverRef.attach(
      new ComponentPortal(PopoverComponent)
    );
    popoverRef.instance.popoverInput = this.data;

    this.listener = this.renderer.listen(
      'document',
      this.data.data.trigger,
      (event: Event) => {
        if (
          !this.isDescendant(
            document.querySelector('.popover.component'),
            event.target
          ) &&
          !this.isDescendant(
            this.elementRef.nativeElement as Element,
            event.target
          )
        ) {
          this.popoverRef.detach();
          this.listener();
        }
      }
    );

    this.popoverRef
      .backdropClick()
      .pipe(first())
      .subscribe(() => {
        this.popoverRef.detach();
      });
  }

  @HostListener('mouseover', ['$event'])
  showOnMouseOver(event: MouseEvent) {
    if (this.data.data.trigger === 'mouseover') {
      this.popoverRef?.detach();
      this.show();
    } else {
      event.preventDefault();
    }
  }

  @HostListener('click', ['$event'])
  showOnMouseClick(event: MouseEvent) {
    if (this.data.data.trigger === 'click') {
      this.popoverRef?.detach();
      this.show(true);
    } else {
      event.preventDefault();
    }
  }

  public ngOnDestroy(): void {
    this.popoverRef?.detach();
    this.backdropClickSubscription?.unsubscribe();
  }

  private isDescendant(parent: any, child: any) {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }
}
