import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IPopoverInput } from '../../interfaces';

@Component({
  selector: 'firebird-web-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopoverComponent {
  @Input()
  popoverInput!: IPopoverInput;
}
