import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ISearchResultResp } from '@firebird-web/shared-interfaces';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'firebird-web-autocomplete-searchbox',
  templateUrl: './autocomplete-searchbox.component.html',
  styleUrls: ['./autocomplete-searchbox.component.scss'],
})
export class AutocompleteSearchboxComponent implements OnInit, OnDestroy {
  @Output()
  public search = new EventEmitter();
  @Output()
  public selectedOption = new EventEmitter();
  @Input() isLoading = false;
  @Input() placeholder = 'searchLocations';
  myControl = new FormControl('');
  public destroyed$ = new Subject();
  @Input() filteredResults!: ISearchResultResp[];

  public ngOnInit(): void {
    this.myControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged((prev, curr) => {
          return prev === curr;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((resp) => {
        if (resp && resp.length >= 3) {
          this.search.emit(resp);
        }
        if (resp === '' || resp === null) {
          this.filteredResults = [];
        }
      });

    this.selectedOption
      .pipe(debounceTime(300), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.myControl.reset();
      });
  }

  public selectionChange(resp: ISearchResultResp) {
    this.selectedOption.emit(resp);
  }

  public ngOnDestroy(): void {
    this.destroyed$?.next(true);
    this.destroyed$?.complete();
  }

  public trackByFnGroup(index: number) {
    return index;
  }

  public getSiteDisplayText(site: ISearchResultResp): string {
    return site.isRegion
      ? site.displayName
      : `${site.displayName}, ${site.stateCode || site.region}`;
  }
}
