<div class="wrapper">
  <div *ngIf="data.type === 'success'">
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 2.75C8.91 2.75 2.75 8.91 2.75 16.5C2.75 24.09 8.91 30.25 16.5 30.25C24.09 30.25 30.25 24.09 30.25 16.5C30.25 8.91 24.09 2.75 16.5 2.75ZM16.5 27.5C10.4363 27.5 5.5 22.5637 5.5 16.5C5.5 10.4363 10.4363 5.5 16.5 5.5C22.5637 5.5 27.5 10.4363 27.5 16.5C27.5 22.5637 22.5637 27.5 16.5 27.5ZM22.8113 10.4225L13.75 19.4837L10.1887 15.9363L8.25 17.875L13.75 23.375L24.75 12.375L22.8113 10.4225Z"
        fill="#157378"
      />
    </svg>
  </div>
  <div>
    <span class="" matSnackBarLabel [innerHTML]="data.message"></span>
  </div>
  <div>
    <firebird-web-icon
      name="cross"
      width="15px"
      height="15px"
      (click)="close()"
    ></firebird-web-icon>
  </div>
</div>
