<a
  [routerLink]="routeUrl"
  [queryParams]="urlQueryParams"
  [queryParamsHandling]="'merge'"
  [ngClass]="{
    active: activeClass,
    'bg-on-hover': type === 'bg-on-hover',
    button: type === 'button'
  }"
  [routerLinkActive]="'active'"
  (click)="click($event)"
  (mouseover)="onMouseOver($event)"
  (mouseout)="onMouseOut($event)"
>
  <span
    *ngIf="iconName"
    class="icon"
    [ngClass]="{ 'space-right': !hideContent }"
  >
    <firebird-web-icon
      [name]="iconName"
      [width]="iconWidth"
      [height]="iconHeight"
      [isActive]="activeClass || isHover"
    ></firebird-web-icon>
  </span>
  <span class="icon-label">
    <ng-content></ng-content>
  </span>
</a>
