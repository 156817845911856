import { ConnectedPosition } from '@angular/cdk/overlay';

export const PopupPositionConfig: ConnectedPosition[] = [
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -3,
    panelClass: 'top',
  },
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 8,
    panelClass: 'bottom',
  },
];
