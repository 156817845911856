<span
  class="icon-component"
  [ngStyle]="{
    width: width,
    height: height,
    fill: fillColor,
    strokeColor: strokeColor
  }"
  *ngIf="name"
>
  <mat-icon [svgIcon]="name + (isActive ? '-active' : '')"></mat-icon>
</span>
