import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IToggleTableInput } from '@firebird-web/shared-ui';

@Component({
  selector: 'firebird-web-toggle-tabs',
  templateUrl: './toggle-tabs.component.html',
  styleUrls: ['./toggle-tabs.component.scss'],
})
export class ToggleTabsComponent {
  @Input() labelData!: IToggleTableInput[];
  @Input() selectedLabelKey!: string | null;

  @Output() toggleTab: EventEmitter<IToggleTableInput> =
    new EventEmitter<IToggleTableInput>();

  public readonly lockIconStyles = { top: '4px', right: '6px' };

  identify(index: number) {
    return index;
  }

  selectTab(event: IToggleTableInput): void {
    this.toggleTab.emit(event);
  }
}
