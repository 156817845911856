import { Component, Input } from '@angular/core';

@Component({
  selector: 'firebird-web-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent {
  @Input()
  public type!: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  @Input()
  public styleOverrides: { [klass: string]: any } = {};

  @Input()
  public labelKey!: string;
}
